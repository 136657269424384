import axios from 'axios';
import { toastError } from '../components/toast';

const redirectToLogin = () => {
    // Limpa todos os dados do localStorage
    localStorage.clear();

    // Redireciona o cliente para o endpoint /login
    window.location.href = '/login';
};

export default function setupAxios() {
    axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3005';

    // Intercepta todas as requisições e adiciona o token ao header
    axios.interceptors.request.use(config => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    }, error => Promise.reject(error));

    // Intercepta todas as respostas e verifica o status
    axios.interceptors.response.use(
        response => response,
        error => {
            if (error.response && error.response.status === 401) {
                redirectToLogin();
            }
            console.log(error);

            let errorMessage = '';

            if (error.response && error.response.data && error.response.data.error) {
                errorMessage = error.response.data.error;
            } else if (error.message) {
                errorMessage = error.message;
            } else if (error.response && error.response.statusText) {
                errorMessage = error.response.statusText;
            } else {
                errorMessage = 'Unknown error occurred';
            }

            toastError(errorMessage);

            console.log('have an error');
            console.error('==============================================');
            console.error(JSON.stringify(error?.response?.data, null, ''));
            console.error('==============================================');

            return Promise.reject(error);
        }
    );

}
