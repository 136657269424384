import React, { useEffect, useState } from 'react';
import ResponsiveTable from '../../components/ResponsiveTable';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Button, Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteButton from '../../components/DeleteButton';
import { toastSuccess } from '../../components/toast';
import RouterBreadcrumbs from '../../components/RouterBreadcrumbs';

const BankAccountsPage = () => {
    const [dataList, setDataList] = useState([]);
    const [loading, setLoading] = useState([]);

    const headers = [
        { attribute: 'name', label: 'Nome' },
        { attribute: 'bank', label: 'Banco' },
        { attribute: 'type', label: 'Tipo' },
        { attribute: 'balance', label: 'Saldo', type: 'money' },
        {
            label: 'Ações',
            width: '15%',
            attribute: '',
            customRender: (bank) => {
                return (
                    <>
                        <Link to={`/banks/${bank?.id}`}>
                            <Button variant="outlined" size="small" sx={{ mr: 1 }}>
                                Editar
                            </Button>
                        </Link>
                        <DeleteButton
                            onConfirm={async () => {
                                try {
                                    await axios.delete(`/accounts/${bank.id}`);
                                    await getDataToList();
                                    toastSuccess('Conta excluida')
                                } catch (ex) { }
                            }}
                            title="Excluir cartão"
                            message={`Você tem certeza que deseja excluir o cartão ${bank.name}?`}
                        />
                    </>
                );
            },
        },
    ];

    async function getDataToList() {
        try {
            setLoading(true)
            const response = await axios.get('/accounts');
            setDataList(response.data);
            setLoading(false)
        } catch (ex) {
            setLoading(false)
        }
    }

    useEffect(() => {
        getDataToList();
    }, []);

    return (
        <>
            <RouterBreadcrumbs />
            <h1>Conta Bancária</h1>
            <ResponsiveTable headers={headers} data={dataList} loading={loading} />
            < Link to="/banks/form" style={{ position: 'fixed', bottom: '24px', right: '24px' }}>
                <Fab color="primary" aria-label="add">
                    <AddIcon />
                </Fab>
            </Link>
        </>
    );
};

export default BankAccountsPage; 
