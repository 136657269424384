import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import CreditCardsPage from './pages/CreditCards/CreditCardsPage';
import CreditCardForm from './pages/CreditCards/CreditCardsForm';
import BankAccountsPage from './pages/BankAccounts/BankAccountsPage';
import BankAccountForm from './pages/BankAccounts/BankAccountsForm';
import PurchasesPage from './pages/purchases/PurchasesPage';
import PurchasesForm from './pages/purchases/PurchasesForm';
import DefaultDashboard from './pages/Dashboards/DefaultDashboard';
import LoginPage from './pages/Login/LoginPage';
import { useSelector } from 'react-redux';
import SignUpPage from './pages/Login/SignUpPage';
import CreditCardInvoicesPage from './pages/CreditCards/CreditCardInvoices/CreditCardInvoicesPage';
import TransfersPage from './pages/transfers/TransfersPage';
import TransfersForm from './pages/transfers/TransfersForm';
import CategoriesForm from './pages/categories/CategoriesForm';
import CategoriesPage from './pages/categories/CategoriesPage';


export const routesArray = [
    {
        path: '/',
        component: DefaultDashboard,
        breadCrumbName: 'Dashboard'
    },
    {
        path: '/cards',
        component: CreditCardsPage,
        breadCrumbName: 'Cartões de Crédito',
        subRoutes: [
            { path: '/form', component: CreditCardForm, breadCrumbName: 'Novo Cartão' },
            { path: '/:id', component: CreditCardForm, breadCrumbName: 'Editar Cartão' },
            { path: '/:creditCardId/invoices', component: CreditCardInvoicesPage, breadCrumbName: 'Faturas do Cartão' }
        ]
    },
    {
        path: '/transfers',
        component: TransfersPage,
        breadCrumbName: 'Transferências',
        subRoutes: [
            { path: '/form', component: TransfersForm, breadCrumbName: 'Nova Transferência' },
            { path: '/:id', component: TransfersForm, breadCrumbName: 'Editar Transferência' }
        ]
    },
    {
        path: '/categories',
        component: CategoriesPage,
        breadCrumbName: 'Categorias',
        subRoutes: [
            { path: '/form', component: CategoriesForm, breadCrumbName: 'Nova Categoria' },
            { path: '/:id', component: CategoriesForm, breadCrumbName: 'Editar Categoria' }
        ]
    },
    {
        path: '/banks',
        component: BankAccountsPage,
        breadCrumbName: 'Contas Bancárias',
        subRoutes: [
            { path: '/form', component: BankAccountForm, breadCrumbName: 'Nova Conta' },
            { path: '/:id', component: BankAccountForm, breadCrumbName: 'Editar Conta' }
        ]
    },
    {
        path: '/purchases',
        component: PurchasesPage,
        breadCrumbName: 'Compras',
        subRoutes: [
            { path: '/form', component: PurchasesForm, breadCrumbName: 'Nova Compra' },
            { path: '/:id', component: PurchasesForm, breadCrumbName: 'Editar Compra' },
            { path: '/shortcut/purchase', component: PurchasesForm, breadCrumbName: 'Atalho para Compra' }
        ]
    }
];
const BaseRoutes = () => {
    const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

    const forceSlashAfterHash = () => {
        let _hash = window.location.hash;
        if (_hash[1] && _hash[1] !== '/') {
            window.location.href = window.location.origin + window.location.pathname + window.location.search + "#/" + _hash.slice(1);
        }
    }

    window.addEventListener('hashchange', forceSlashAfterHash);


    const authRoutes = [
        { path: '/login', component: LoginPage },
        { path: '/signup', component: SignUpPage }
    ];

    const renderRoutes = (routeArray, arrayRouteComponent = []) => {
        routeArray.map((route) => {
            arrayRouteComponent.push(<Route key={route.path} path={route.path} Component={route.component} />)

            if (route.subRoutes) {
                renderRoutes(route.subRoutes.map(subRoute => ({
                    ...subRoute,
                    path: route.path + subRoute.path
                })), arrayRouteComponent)
            }
            return null;
        });
        return arrayRouteComponent;
    };
    return (
        <Routes>
            {isLoggedIn ? renderRoutes(routesArray) : renderRoutes(authRoutes)}
            <Route path="*" element={<Navigate to={isLoggedIn ? "/" : "/login"} replace />} />
        </Routes>
    );
};

export default BaseRoutes;
