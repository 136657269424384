import React from "react";
import { Field } from "formik";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProvider, DatePicker } from "@mui/lab";
import { TextField } from "@mui/material";

const DateFieldComponent = ({ name, label, ...rest }) => {
    return (
        <Field name={name}>
            {({ field, form, meta }) => {
                const selectedDate = field.value ? new Date(field.value) : null;

                const handleDateChange = (date) => {
                    form.setFieldValue(name, date.toISOString());
                };

                return (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            label={label}
                            value={selectedDate}
                            onChange={handleDateChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    error={meta.touched && Boolean(meta.error)}
                                    helperText={meta.touched && meta.error}
                                />
                            )}
                            {...rest}
                        />
                    </LocalizationProvider>
                );
            }}
        </Field>
    );
};

const DateField = ({ name, label, ...rest }) => {
    return (
        <Field name={name}>
            {({ field, form, meta }) => (
                <TextField
                    {...field}
                    id={name}
                    label={label}
                    error={meta.touched && Boolean(meta.error)}
                    helperText={meta.touched && meta.error}
                    variant="outlined"
                    type="date"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    {...rest}
                />
            )}
        </Field>
    );
};

export { DateField, DateFieldComponent };
