import * as React from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { mainMenuItensList } from './menuItemsList';
import { Link, useLocation } from 'react-router-dom';

const BottomNavigationComponent = () => {
    const location = useLocation();
    const [selectedBottom, setSelectedBottom] = React.useState('');

    const handleChange = (event, newValue) => {
        const menuItem = mainMenuItensList.find(item => item.href === newValue);
        if (menuItem) {
            setSelectedBottom(newValue);
        } else {
            setSelectedBottom(mainMenuItensList[Math.floor(mainMenuItensList.length / 2)].href);
        }
    };

    let updatedMainMenuItensList = mainMenuItensList.slice();
    updatedMainMenuItensList.splice(Math.floor(mainMenuItensList.length / 2), 0);

    React.useEffect(() => {
        const problablyLocation = updatedMainMenuItensList.find(x => location?.pathname?.includes(x?.href))
        if (problablyLocation?.href !== selectedBottom) {
            setSelectedBottom(problablyLocation?.href)
        }
    }, [location.pathname, selectedBottom, updatedMainMenuItensList]);

    return (
        <div style={{ width: '100%', borderRadius: '12px', overflow: 'hidden' }} id="BottomNavigation_system">
            <BottomNavigation
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    backgroundColor: '#f1f1f1',
                }}
                value={selectedBottom}
                onChange={handleChange}
            >
                {updatedMainMenuItensList.filter(item => item.showBottom).map((item) => (
                    <BottomNavigationAction
                        key={item.href}
                        component={Link}
                        to={item.href}
                        label={item.primary}
                        value={item.href}
                        icon={<i className={`${item.icon} big icon-beat`}></i>}
                        sx={{
                            flex: '1 1 auto',
                            textAlign: 'center',
                            borderRadius: '12px',
                            backgroundColor: item.href === selectedBottom ? '#d8d8d8' : 'transparent',
                            color: item.href === selectedBottom ? '#000' : '#888',
                        }}
                    />
                ))}
            </BottomNavigation>
        </div>
    );
};

export default BottomNavigationComponent;
