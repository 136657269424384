import React, { useState, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import { useField } from 'formik';

export const AutocompleteComponent = ({ getOptionLabel, label, path, ...rest }) => {
    const [options, setOptions] = useState([]);
    const [loadingOptions, setLoadingOptions] = useState(false);
    const [firstLoad, setfirstLoad] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [field, meta, helpers] = useField(rest.name);
    const [fieldSelectedName, setFieldSelectedName] = useState('');

    useEffect(() => {
        if (path && !firstLoad) {
            setfirstLoad(true);
            const fetchData = async () => {
                setLoadingOptions(true);
                try {
                    const response = await axios.get(path);
                    if (rest.customFilter)
                        setOptions(rest.customFilter(response.data));
                    else
                        setOptions(response.data);
                    setLoadingOptions(false);
                } catch (error) {
                    console.log(error);
                }
            };
            fetchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const actualOption = options.find((x) => x.id === field.value);
        if (actualOption) {
            setFieldSelectedName(getOptionLabel(actualOption));
        } else if (options.length === 1) {
            // Se houver apenas um valor, selecione-o por padrão
            const singleOption = options[0];
            helpers.setValue(singleOption.id);
            setFieldSelectedName(getOptionLabel(singleOption));
        }
    }, [field.value, getOptionLabel, options, helpers]);

    return (
        <Autocomplete
            options={options}
            getOptionLabel={getOptionLabel}
            inputValue={fieldSelectedName}
            renderInput={(params) => (
                <TextField {...params} label={label} variant="outlined" value={fieldSelectedName} fullWidth />
            )}
            {...field}
            value={options.find((x) => x.id === field.value) || null}
            defaultValue={options.find((x) => x.id === field.value) || null}
            onChange={(event, newValue) => {
                helpers.setValue(newValue ? newValue.id : '');
            }}
            loading={loadingOptions}
            renderOption={rest.renderOption}
        />
    );
};

export default AutocompleteComponent;
