import { AutocompleteComponent } from '../fields/AutocompleteComponent'

export const CreditCardsAutocompleteComponent = ({ onChange, ...rest }) => {
    return (
        <AutocompleteComponent
            {...rest}
            path='/cards/'
            getOptionLabel={option => option.name}
            onChange={(_, value) => onChange(value?.id)}
        />
    );
};


export default CreditCardsAutocompleteComponent;
