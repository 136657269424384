import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import ConfirmationPopup from '../ConfirmationPopup';

const CustomizableButton = ({ onConfirm, title, icon, customFields, message }) => {
    const [isConfirmationPopupOpen, setIsConfirmationPopupOpen] = useState(false);

    const handleClick = () => {
        setIsConfirmationPopupOpen(true);
    };

    const handleConfirm = () => {
        setIsConfirmationPopupOpen(false);
        onConfirm();
    };

    const handleCancel = () => {
        setIsConfirmationPopupOpen(false);
    };

    return (
        <>
            <IconButton aria-label="button" onClick={handleClick}>
                {icon}
            </IconButton>
            {isConfirmationPopupOpen && (
                <ConfirmationPopup
                    open={isConfirmationPopupOpen}
                    onCancel={handleCancel}
                    onConfirm={handleConfirm}
                    message={message}
                    title={title}
                    customFields={customFields}
                />
            )}
        </>
    );
};

CustomizableButton.propTypes = {
    onConfirm: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    icon: PropTypes.node.isRequired,
    customFields: PropTypes.func,
    message: PropTypes.string,
};

export default CustomizableButton;
