import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { useContext, useState, useEffect, useCallback } from 'react';
import { LoadingContext } from '../../components/LoadingContext';
import { toastSuccess } from '../../components/toast';
import RouterBreadcrumbs from '../../components/RouterBreadcrumbs';

const CategoriesForm = () => {
    const { id } = useParams();
    const [editing, setEditing] = useState(!!id);
    const navigate = useNavigate();
    const { setLoading } = useContext(LoadingContext);

    const validationSchema = yup.object().shape({
        name: yup.string().required('Nome é obrigatório.'),
        description: yup.string().nullable(),
        icon: yup.string().nullable(), // Validação para o campo icon
    });

    const handleSubmit = async (values) => {
        setLoading(true);
        try {
            if (editing) {
                await axios.put(`/categories/${id}`, values);
            } else {
                await axios.post('/categories', values);
            }
            toastSuccess(editing ? 'Categoria atualizada' : 'Categoria criada');
            navigate('/categories');
        } catch (error) {
            console.error('Erro ao salvar categoria', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchCategory = useCallback(
        async () => {
            if (editing) {
                setLoading(true);
                try {
                    const response = await axios.get(`/categories/${id}`);
                    setInitialValues(response.data);
                } catch (error) {
                    console.error('Erro ao carregar categoria', error);
                } finally {
                    setLoading(false);
                }
            }
        },
        [id, editing, setLoading]
    );

    useEffect(() => {
        fetchCategory();
    }, [fetchCategory]);

    const [initialValues, setInitialValues] = useState({
        name: '',
        description: '',
        icon: '', // Inicialização para o campo icon
    });

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {({ errors, touched, values, resetForm }) => (
                <Form>
                    <RouterBreadcrumbs />
                    <Box my={2}>
                        <Typography variant="h6">{editing ? 'Editar Categoria' : 'Nova Categoria'}</Typography>
                    </Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Field
                                as={TextField}
                                id="name"
                                name="name"
                                label="Nome"
                                fullWidth
                                variant="outlined"
                                error={touched.name && Boolean(errors.name)}
                                helperText={touched.name && errors.name}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Field
                                as={TextField}
                                id="description"
                                name="description"
                                label="Descrição"
                                fullWidth
                                variant="outlined"
                                error={touched.description && Boolean(errors.description)}
                                helperText={touched.description && errors.description}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Field
                                as={TextField}
                                id="icon"
                                name="icon"
                                label="Ícone (URL ou escolha)"
                                fullWidth
                                variant="outlined"
                                error={touched.icon && Boolean(errors.icon)}
                                helperText={touched.icon && errors.icon}
                            />
                        </Grid>
                    </Grid>
                    <Box mt={2} mb={2}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <Button type="submit" variant="contained" color="primary" fullWidth>
                                    {editing ? 'Atualizar' : 'Criar'}
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    fullWidth
                                    onClick={async (e) => {
                                        e.preventDefault();
                                        setLoading(true);
                                        await handleSubmit(values);
                                        resetForm();
                                        setLoading(false);
                                    }}
                                >
                                    Salvar e adicionar novo
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    fullWidth
                                    href="/#/categories"
                                >
                                    Cancelar
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Form>
            )}
        </Formik>
    );
};

export default CategoriesForm;
