import React from "react";
import TextField from "@mui/material/TextField";
import { useField } from "formik";
import MenuItem from "@mui/material/MenuItem";
import { Grid } from "@mui/material";

const CreditCardBrandSelect = ({ label, ...rest }) => {
    const [meta] = useField(rest);

    const creditCardBrands = [
        { value: "Visa", label: "Visa", icon: <i className="fa-brands fa-cc-visa" style={{ marginRight: "8px" }} ></i> },
        { value: "Mastercard", label: "Mastercard", icon: <i className="fa-brands fa-cc-mastercard"></i> },
        { value: "Elo", label: "Elo", icon: <i className="fa-solid fa-credit-card"></i> }
    ];

    return (
        <>
            <TextField
                select
                label={label}
                error={meta.touched && !!meta.error}
                helperText={meta.touched && meta.error}
                fullWidth
                variant="outlined"
                {...rest}
            >
                {creditCardBrands.map((brand) => (
                    <MenuItem key={brand.value} value={brand.value}>
                        <Grid container spacing={2}>
                            <Grid item xs={11}>
                                <div>
                                    {brand.label}
                                </div >
                                <div>
                                </div>
                            </Grid>
                            <Grid item xs={1}>
                                {brand.icon}
                            </Grid>
                        </Grid>
                    </MenuItem>
                ))}
            </TextField>
        </>
    );
};

export { CreditCardBrandSelect };
