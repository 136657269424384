import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import MoneyInput from "../../components/fields/MoneyInput";
import { CreditCardBrandSelect } from "../../components/fields/creditCardBrands";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { LoadingContext } from "../../components/LoadingContext";
import { toastSuccess } from "../../components/toast";
import RouterBreadcrumbs from "../../components/RouterBreadcrumbs";

const CreditCardForm = () => {
    const { id } = useParams();
    const [editing, setEditing] = useState(id);
    const [initialValues, setInitialValues] = useState({
        name: "",
        brand: "",
        limitCredit: "",
        closingDay: "",
        dueDay: "",
    });
    const { setLoading } = useContext(LoadingContext);

    const navigate = useNavigate();

    const getDataToEdit = async (id) => {
        if (id) {
            setLoading(true);
            const response = await axios.get(`/cards/${id}`);
            setInitialValues(response.data);
            setLoading(false);
        }
    };

    useEffect(() => {
        getDataToEdit(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const validationSchema = yup.object().shape({
        name: yup
            .string()
            .trim()
            .required("O nome é obrigatório")
            .max(100, "O nome deve ter no máximo 100 caracteres"),
        brand: yup
            .string()
            .trim()
            .required("A bandeira é obrigatória")
            .max(50, "A bandeira deve ter no máximo 50 caracteres"),
        limitCredit: yup
            .number()
            .required("O limite de crédito é obrigatório")
            .positive("O limite de crédito deve ser positivo")
            .typeError("O limite de crédito deve ser um número válido"),
        closingDay: yup
            .number()
            .required("O dia de fechamento é obrigatório")
            .integer("O dia de fechamento deve ser um número inteiro")
            .min(1, "O dia de fechamento deve ser maior ou igual a 1")
            .max(31, "O dia de fechamento deve ser menor ou igual a 31")
            .typeError("O dia de fechamento deve ser um número válido")
            .test(
                "closing-day-before-due-day",
                "O dia de fechamento deve ser menor que o dia de vencimento",
                function (value) {
                    const { dueDay } = this.parent;
                    return value < dueDay || !value || !dueDay;
                }
            ),
        dueDay: yup
            .number()
            .required("O dia de vencimento é obrigatório")
            .integer("O dia de vencimento deve ser um número inteiro")
            .min(1, "O dia de vencimento deve ser maior ou igual a 1")
            .max(31, "O dia de vencimento deve ser menor ou igual a 31")
            .typeError("O dia de vencimento deve ser um número válido")
            .test(
                "due-day-after-closing-day",
                "O dia de vencimento deve ser maior que o dia de fechamento",
                function (value) {
                    const { closingDay } = this.parent;
                    return value > closingDay || !value || !closingDay;
                }
            ),
    });

    const handleSubmit = (values, formik, stayThisPage = false) => {
        setLoading(true);
        if (editing) {
            axios
                .put(`/cards/${id}`, values)
                .then(() => {
                    setEditing(false);
                    setLoading(false);
                    toastSuccess('Cartão atualizado')
                    if (!stayThisPage)
                        navigate("/cards")();
                })
                .catch((error) => console.error(error));
        } else {
            axios
                .post("/cards", values)
                .then(() => {
                    setLoading(false);
                    toastSuccess('Cartão cadastrado')
                    if (!stayThisPage)
                        navigate("/cards")();
                })
                .catch((error) => console.error(error));
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {({ errors, touched, values, resetForm }) => (
                <Form>
                    <RouterBreadcrumbs />
                    <Box my={2}>
                        <Typography variant="h6">Formulário</Typography>
                    </Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                            <Field
                                as={TextField}
                                id="name"
                                name="name"
                                label="Nome"
                                fullWidth
                                variant="outlined"
                            />
                            {touched.name && errors.name && <div>{errors.name}</div>}
                        </Grid>
                        <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                            <Field
                                as={CreditCardBrandSelect}
                                id="brand"
                                name="brand"
                                label="Bandeira"
                                fullWidth
                                variant="outlined"
                            />
                            {touched.brand && errors.brand && <div>{errors.brand}</div>}
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                            <Field
                                as={MoneyInput}
                                id="limitCredit"
                                name="limitCredit"
                                label="Limite de Crédito"
                                fullWidth
                                variant="outlined"
                            />
                            {touched.limitCredit && errors.limitCredit && (
                                <div>{errors.limitCredit}</div>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                            <Field
                                as={TextField}
                                id="closingDay"
                                name="closingDay"
                                label="Dia de Fechamento"
                                fullWidth
                                variant="outlined"
                                type="number"
                            />
                            {touched.closingDay && errors.closingDay && (
                                <div>{errors.closingDay}</div>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                            <Field
                                as={TextField}
                                id="dueDay"
                                name="dueDay"
                                label="Dia de Vencimento"
                                fullWidth
                                variant="outlined"
                                type="number"
                            />
                            {touched.dueDay && errors.dueDay && <div>{errors.dueDay}</div>}
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Box mt={2} mb={2}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                        >
                                            Enviar
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            fullWidth
                                            onClick={async (e) => {
                                                e.preventDefault();
                                                setLoading(true);
                                                await handleSubmit(values, null, true);
                                                resetForm()

                                                setLoading(false);
                                            }}
                                        >
                                            Salvar e adicionar novo
                                        </Button>

                                    </Grid>

                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            fullWidth
                                            href="/#/cards"
                                        >
                                            Cancelar
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};

export default CreditCardForm;
