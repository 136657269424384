import React, { useState } from 'react';
import {
    CssBaseline,
    Box,
    Toolbar,
    List,
    Typography,
    IconButton,
    Container,
    useMediaQuery,
    styled,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Drawer from './layout/Drawer';
import AppBar from './layout/AppBar';
import UserInfo from './layout/UserInfo';
import BottomNavigationComponent from './layout/BottomNavigationComponent';
import { useSelector } from 'react-redux';
import { MainListItems } from './layout/MenuItens';

const Footer = styled('footer')(({ theme }) => ({
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: theme.palette.background.paper,
    textAlign: 'center',
    padding: theme.spacing(2),
}));

const PageContent = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    minHeight: 'calc(100vh - 74px - 56px)',
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    boxShadow: theme.shadows[4],
    overflow: 'hidden', // Desativa a rolagem vertical
}));

export default function LayoutComponent(props) {
    const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
    const isSmallScreen = useMediaQuery('(max-width:800px)');
    const [open, setOpen] = useState(!isSmallScreen);

    if (!isLoggedIn) {
        return props.children;
    }

    const toggleDrawer = () => {
        setOpen(!open);
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="absolute" open={open}>
                <Toolbar sx={{ pr: '24px' }}>
                    {!isSmallScreen && (
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={toggleDrawer}
                            sx={{ marginRight: '36px', ...(open && { display: 'none' }) }}
                        >
                            <MenuIcon />
                        </IconButton>
                    )}
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{ flexGrow: 1 }}
                    >
                        WiseSpending
                    </Typography>
                    <UserInfo
                        username="Nome do Usuário"
                        avatarUrl="https://www.example.com/avatar.png"
                    />
                </Toolbar>
            </AppBar>
            {!isSmallScreen && (
                <Drawer variant="permanent" open={open}>
                    <Toolbar
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            px: [1],
                        }}
                    >
                        <IconButton onClick={toggleDrawer}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </Toolbar>
                    {/* <Divider /> */}
                    <List component="nav" sx={{ paddingTop: 0 }}>
                        <MainListItems DrawerOpen={open} />
                        {/* <Divider sx={{ my: 1 }} />
                        {secondaryListItems} */}
                    </List>
                </Drawer>
            )}

            <Box
                component="main"
                sx={{
                    backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                            ? theme.palette.grey[100]
                            : theme.palette.grey[900],
                    flexGrow: 1,
                    overflow: 'hidden', // Desativa a rolagem vertical
                }}
            >
                <Toolbar />
                <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
                    <PageContent>{props.children}</PageContent>
                </Container>
            </Box>
            <Footer>
                {isSmallScreen && (
                    <Box>
                        <BottomNavigationComponent />
                    </Box>
                )}
            </Footer>
        </Box>
    );
}
