import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmationPopup from './ConfirmationPopup';

const DeleteButton = ({ onConfirm, title, message }) => {
    const [isConfirmationPopupOpen, setIsConfirmationPopupOpen] = useState(false);

    const handleDeleteClick = () => {
        setIsConfirmationPopupOpen(true);
    };

    const handleDeleteConfirm = () => {
        setIsConfirmationPopupOpen(false);
        onConfirm();
    };

    const handleDeleteCancel = () => {
        setIsConfirmationPopupOpen(false);
    };

    return (
        <>
            <IconButton aria-label="delete" onClick={handleDeleteClick}>
                <DeleteIcon />
            </IconButton>
            {isConfirmationPopupOpen &&
                <ConfirmationPopup
                    open={isConfirmationPopupOpen}
                    onCancel={handleDeleteCancel}
                    onConfirm={handleDeleteConfirm}
                    title={title}
                    message={message}
                />
            }
        </>
    );
};

export default DeleteButton;
