import React from 'react';
import './index.css';
import BaseRoutes from './BaseRoutes';
import LayoutComponent from './components/layoutComponent';
import { HashRouter } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme';
import setupAxios from './services/AxiosConfig';
import axios from 'axios';
import { Provider } from 'react-redux';
import store from './redux/store';
import { LoadingProvider } from './components/LoadingContext';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import DisableContextMenu from './components/DisableContextMenu';

setupAxios(axios);

const App = () => {
    return (
        <React.StrictMode>
            <React.Fragment> {/* Fragmento para evitar renderizar um elemento pai adicional */}
                <DisableContextMenu />
                <LoadingProvider>
                    <Provider store={store}>
                        <ThemeProvider theme={theme}>
                            <CssBaseline />
                            <HashRouter>
                                <LayoutComponent>
                                    <BaseRoutes />
                                </LayoutComponent>
                            </HashRouter>
                        </ThemeProvider>
                    </Provider>
                </LoadingProvider>
                <ToastContainer />
            </React.Fragment>
        </React.StrictMode>
    );
};

export default App;
