import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import MoneyInput from '../../components/fields/MoneyInput';
import { useNavigate, useParams } from 'react-router-dom';
import { SelectFieldComponent } from '../../components/fields/SelectFieldComponent';
import { DateField } from '../../components/fields/DateFieldComponent';
import { CreditCardsAutocompleteComponent } from '../../components/autocompleteComponents/CreditCardsAutocompleteComponent';
import AccountsAutocompleteComponent from '../../components/autocompleteComponents/AccountsAutocompleteComponent';
import { LoadingContext } from '../../components/LoadingContext';
import { toastSuccess } from '../../components/toast';
import RouterBreadcrumbs from '../../components/RouterBreadcrumbs';
import ResponsiveTable from '../../components/ResponsiveTable';
import CategoriesAutocompleteComponent from '../../components/autocompleteComponents/CategoriesAutocompleteComponent';

const PurchasesForm = () => {
    const { id } = useParams();
    const [editing, setEditing] = useState(!!id);
    const [initialValues, setInitialValues] = useState({
        purchaseDate: new Date().toISOString().substr(0, 10),
        description: '',
        amount: '',
        paymentType: 'credit',
        installmentCount: 1,
        accountId: null,
        creditCardId: null
    });
    const { setLoading } = useContext(LoadingContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (id) {
            setLoading(true);
            axios.get(`/purchases/${id}`)
                .then(response => {
                    setInitialValues(response.data);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [id]);

    const validationSchema = yup.object().shape({
        purchaseDate: yup.date().required().label('Data da Despesa'),
        description: yup.string().max(200).required().label('Descrição'),
        amount: yup.number().positive().required().label('Valor'),
        categoryId: yup.number().integer().positive().nullable().notRequired().label('Categoria'),
        accountId: yup.number().nullable().label('Conta'),
        creditCardId: yup.number().nullable().label('Cartão de Crédito'),
        paymentType: yup.string().nullable().oneOf(['credit', 'debit']).label('Tipo de Pagamento'),
        installmentCount: yup.number().positive().label('Quantidade de Parcelas')
    }).test('credit_card_or_accountId_required', 'Deve ser preenchido o id do cartão de crédito ou da conta', function (value) {
        const { creditCardId, accountId, paymentType } = value || {};
        return paymentType === 'credit' ? !!creditCardId : !!accountId;
    });

    const handleSubmit = (values, { resetForm }) => {
        const request = editing ? axios.put(`/purchases/${id}`, values) : axios.post('/purchases', values);

        request
            .then(() => {
                setEditing(false);
                toastSuccess('Despesa ' + (editing ? 'atualizada' : 'cadastrada'));
                if (!editing) navigate('/purchases')();
            })
            .catch(error => console.error(error))
            .finally(() => {
                resetForm();
                setLoading(false);
            });

        setLoading(true);
    };

    const headersInstallments = [
        { attribute: 'parcela', label: 'Parcela', type: 'number' },
        { attribute: 'valor', label: 'Valor', type: 'money' },
        { attribute: 'vencimento', label: 'Vencimento', type: 'date' },
    ];

    const calculateInstallments = (amount, installmentCount, purchaseDate) => {
        const installmentValue = amount / installmentCount;
        const installments = [];

        for (let i = 0; i < installmentCount; i++) {
            const dueDate = new Date(purchaseDate);
            dueDate.setMonth(dueDate.getMonth() + i);

            installments.push({
                parcela: i + 1,
                valor: installmentValue.toFixed(2),
                vencimento: dueDate.toISOString().substr(0, 10)
            });
        }

        return installments;
    };

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {({ errors, touched, values, resetForm }) => {
                const dataList = calculateInstallments(values.amount, values.installmentCount, values.purchaseDate);

                return (
                    <Form>
                        <RouterBreadcrumbs />
                        <Box my={2}>
                            <Typography variant="h6">Formulário</Typography>
                        </Box>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={4}>
                                <Field
                                    as={DateField}
                                    id="purchaseDate"
                                    name="purchaseDate"
                                    label="Data da Despesa"
                                    fullWidth
                                    variant="outlined"
                                    type="date"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} md={8}>
                                <Field
                                    as={TextField}
                                    id="description"
                                    name="description"
                                    label="Descrição"
                                    fullWidth
                                    variant="outlined"
                                    error={touched.description && Boolean(errors.description)}
                                    helperText={touched.description && errors.description}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                                <Field
                                    as={MoneyInput}
                                    id="amount"
                                    name="amount"
                                    label="Valor"
                                    fullWidth
                                    variant="outlined"
                                    error={touched.amount && Boolean(errors.amount)}
                                    helperText={touched.amount && errors.amount}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                                <Field
                                    as={CategoriesAutocompleteComponent}
                                    id="categoryId"
                                    name="categoryId"
                                    label="Categoria"
                                    fullWidth
                                    variant="outlined"
                                    error={touched.accountId && Boolean(errors.accountId)}
                                    helperText={touched.accountId && errors.accountId}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                                <Field
                                    as={SelectFieldComponent}
                                    id="paymentType"
                                    name="paymentType"
                                    label="Tipo de Pagamento"
                                    fullWidth
                                    variant="outlined"
                                    options={[
                                        { value: "debit", label: "Débito" },
                                        { value: "credit", label: "Crédito" },
                                    ]}
                                    error={touched.paymentType && Boolean(errors.paymentType)}
                                    helperText={touched.paymentType && errors.paymentType}
                                />
                            </Grid>

                            {values.paymentType === "debit" && (
                                <Grid item xs={12} sm={6} md={6}>
                                    <Field
                                        as={AccountsAutocompleteComponent}
                                        id="accountId"
                                        name="accountId"
                                        label="Conta"
                                        fullWidth
                                        variant="outlined"
                                        error={touched.accountId && Boolean(errors.accountId)}
                                        helperText={touched.accountId && errors.accountId}
                                    />
                                </Grid>
                            )}

                            {values.paymentType === "credit" && (
                                <>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <Field
                                            as={CreditCardsAutocompleteComponent}
                                            id="creditCardId"
                                            name="creditCardId"
                                            label="Cartão de Crédito"
                                            fullWidth
                                            variant="outlined"
                                            error={touched.creditCardId && Boolean(errors.creditCardId)}
                                            helperText={touched.creditCardId && errors.creditCardId}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <Field
                                            as={TextField}
                                            id="installmentCount"
                                            name="installmentCount"
                                            label="Quantidade de Parcelas"
                                            type="number"
                                            fullWidth
                                            variant="outlined"
                                            error={touched.installmentCount && Boolean(errors.installmentCount)}
                                            helperText={touched.installmentCount && errors.installmentCount}
                                            inputProps={{ min: 1 }}
                                        />
                                    </Grid>
                                </>
                            )}
                            <Grid item xs={12}>
                                {values.paymentType === "credit" && (
                                    <ResponsiveTable headers={headersInstallments} data={dataList} loading={false} />
                                )}
                            </Grid>

                            <Grid item xs={12}>
                                <Box mt={2} mb={2}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={4}>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                fullWidth
                                            >
                                                Enviar
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                fullWidth
                                                onClick={async (e) => {
                                                    e.preventDefault();
                                                    setLoading(true);
                                                    await handleSubmit(values, null, true);
                                                    resetForm();
                                                    setLoading(false);
                                                }}
                                            >
                                                Salvar e adicionar novo
                                            </Button>
                                        </Grid>

                                        <Grid item xs={12} sm={4}>
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                fullWidth
                                                href='/#/purchases'
                                            >
                                                Cancelar
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Form>
                );
            }}
        </Formik>

    );
};

export default PurchasesForm;
