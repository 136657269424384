import { Breadcrumbs, Link, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { routesArray } from '../BaseRoutes';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
function findRouteByPath(pathname, routes) {
    for (const route of routes) {
        const routePath = route.path.replace(/:w+/g, '[^/]+'); // Corrige a expressão regular
        const regex = new RegExp(`^${routePath}$`);
        if (regex.test(pathname)) {
            return route;
        }
        if (route.subRoutes) {
            const found = findRouteByPath(pathname, route.subRoutes);
            if (found) {
                return found;
            }
        }
    }
    return null;
}

export default function RouterBreadcrumbs() {
    const location = useLocation();
    const params = useParams();

    // Constrói o caminho da rota com parâmetros substituídos por seus nomes
    const routerPath = Object.entries(params).reduce((path, [key, value]) => {
        return path.replace(value, `:${key}`);
    }, location.pathname);

    // Cria uma lista plana de rotas com subrotas
    const arrayOfRoutes = routesArray.flatMap(route =>
        route.subRoutes ? [route, ...route.subRoutes.map(sub => ({ ...sub, path: route.path + sub.path }))] : [route]
    );

    const pathSegments = routerPath.split('/').filter(Boolean);
    const breadcrumbs = pathSegments.reduce((acc, segment, index) => {
        const path = `/${pathSegments.slice(0, index + 1).join('/')}`;
        const route = findRouteByPath(path, arrayOfRoutes);
        if (route) {
            acc.push({
                path,
                label: route.breadCrumbName || segment
            });
        }
        return acc;
    }, []);

    return (
        <div role="presentation">
            <Breadcrumbs aria-label="breadcrumb">
                <Link
                    key="home"
                    underline="hover"
                    sx={{ display: 'flex', alignItems: 'center' }}
                    color="inherit"
                    href={"#/"}
                >
                    <Tooltip title="Inicio" >
                        <HomeOutlinedIcon />
                    </Tooltip>
                </Link>
                {breadcrumbs.map((crumb, index) => (
                    index < breadcrumbs.length - 1 ? (
                        <Link
                            key={crumb.path}
                            underline="hover"
                            sx={{ display: 'flex', alignItems: 'center' }}
                            color="inherit"
                            href={"#" + crumb.path}
                        >
                            {crumb.label}
                        </Link>
                    ) : (
                        <Typography
                            key={crumb.path}
                            sx={{ display: 'flex', alignItems: 'center' }}
                            color="text.primary"
                        >
                            {crumb.label}
                        </Typography>
                    )
                ))}
            </Breadcrumbs>
        </div>
    );
}
