import React, { useEffect, useState } from 'react';
import ResponsiveTable from '../../components/ResponsiveTable';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Button, Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteButton from '../../components/DeleteButton';
import { toastSuccess } from '../../components/toast';
import RouterBreadcrumbs from '../../components/RouterBreadcrumbs';

const PurchasesPage = () => {
    const [dataList, setDataList] = useState([]);
    const [loading, setLoading] = useState(false);

    const headers = [
        { attribute: 'purchaseDate', label: 'Data da Despesa', type: 'date' },
        { attribute: 'description', label: 'Descrição' },
        { attribute: 'amount', label: 'Valor', type: 'money' },
        { attribute: 'category', label: 'Categoria', type: 'category' },
        {
            attribute: 'paymentType', label: 'Metodo de Pagamento', type: 'custom', customRender: (values) => {
                return values.paymentType === 'credit' ? 'Crédito' : 'Débito';
            }
        },
        {
            label: 'CC/CB', type: 'custom', customRender: (values) => {
                if (values.paymentType === 'credit' && values.creditCardId) {
                    return values?.creditCard?.name
                } else if (values.paymentType === 'debit' && values.accountId) {
                    return values?.account?.name
                }
                return ''
            }
        },
        {
            label: 'Ações',
            width: '15%',
            attribute: '',
            customRender: (purchase) => (
                <>
                    <Link to={`/purchases/${purchase.id}`}>
                        <Button variant="outlined" size="small" sx={{ mr: 1 }}>
                            Editar
                        </Button>
                    </Link>
                    <DeleteButton
                        onConfirm={async () => {
                            try {
                                await axios.delete(`/purchases/${purchase.id}`);
                                toastSuccess('Despesa excluida');
                            } catch (error) {

                            }
                            await getDataToList();
                        }}
                        title="Excluir despesa"
                        message={`Voc  tem certeza que deseja excluir a despesa ${purchase.description}?`}
                    />
                </>
            ),
        },
    ];

    async function getDataToList() {
        setLoading(true);
        try {
            const response = await axios.get('/purchases');
            setDataList(response.data);
        } catch (ex) {

        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getDataToList();
    }, []);

    return (
        <>
            <RouterBreadcrumbs />
            <h1>Despesas</h1> 
            <ResponsiveTable headers={headers} data={dataList} loading={loading} />
            <Link to="/purchases/form" style={{ position: 'fixed', bottom: '24px', right: '24px' }}>
                <Fab color="primary" aria-label="add">
                    <AddIcon />
                </Fab>
            </Link>
        </>
    );
};

export default PurchasesPage; 
