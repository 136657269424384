import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import MoneyInput from '../../components/fields/MoneyInput';
import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SelectFieldComponent } from '../../components/fields/SelectFieldComponent';
import { LoadingContext } from '../../components/LoadingContext';
import { isNumber } from 'lodash';
import { toastSuccess } from '../../components/toast';
import RouterBreadcrumbs from '../../components/RouterBreadcrumbs';

const BankForm = () => {
    const { id } = useParams();
    const [editing, setEditing] = useState(isNumber(id));
    const [initialValues, setInitialValues] = useState({ name: "", bank: "", type: "", balance: "" })

    const navigate = useNavigate()
    const { setLoading } = useContext(LoadingContext)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getDataToEdit = async (id) => {
        if (id) {
            setLoading(true)
            const response = await axios.get(`/accounts/${id}`).finally(() => {
                setLoading(false)
            })
            setInitialValues(response.data)
        }
    }

    useEffect(() => {
        if (id)
            getDataToEdit(id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    const validationSchema = yup.object().shape({
        name: yup.string().required("Preciso que você de um nome para essa conta :("),
        bank: yup.string().required("É uma ótima ideia identificar o banco, vai ficar melhor depois ;)"),
        branch: yup.string().nullable(),
        number: yup.string().nullable(),
        type: yup.string().max(20).nullable(),
        balance: yup.number().min(0).default(0).nullable(),
    });

    const handleSubmit = (values, formik, stayThisPage = false) => {
        setLoading(true)
        if (editing || id) {
            axios.put(`/accounts/${id}`, values)
                .then(() => {
                    setEditing(false);
                    toastSuccess('Conta atualizada')
                    if (!stayThisPage)
                        navigate('/banks')()
                })
                .catch((error) => console.error(error))
                .finally(() => {
                    setLoading(false)
                })
        } else {
            axios.post('/accounts', values)
                .then(() => {
                    toastSuccess('Conta cadastrada')
                    if (!stayThisPage)
                        navigate('/banks')()
                })
                .catch((error) => console.error(error))
                .finally(() => {
                    setLoading(false)
                })
        }
    };
    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {({ errors, touched, values, resetForm }) => (
                <Form>
                    <RouterBreadcrumbs />
                    <Box my={2}>
                        <Typography variant="h6">Formulário</Typography>
                    </Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={8} md={12} lg={7} xl={7} >
                            <Field
                                as={TextField}
                                id="name"
                                name="name"
                                label="Nome"
                                fullWidth
                                variant="outlined"
                            />
                            {touched.name && errors.name && (
                                <div>{errors.name}</div>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={4} md={3} lg={5} xl={5}>
                            <Field
                                as={TextField}
                                id="bank"
                                name="bank"
                                label="Banco"
                                fullWidth
                                variant="outlined"
                            />
                            {touched.bank && errors.bank && (
                                <div>{errors.bank}</div>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <Field
                                as={SelectFieldComponent}
                                id="type"
                                name="type"
                                label="Tipo"
                                fullWidth
                                variant="outlined"
                                options={[
                                    { value: "CC", label: "Conta Corrente" },
                                    { value: "CS", label: "Conta Salário" },
                                    { value: "P", label: "Poupança" },
                                    { value: "I", label: "Investimento" }
                                ]}
                            />
                            {touched.type && errors.type && (
                                <div>{errors.type}</div>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <Field
                                as={MoneyInput}
                                id="balance"
                                name="balance"
                                label="Saldo"
                                fullWidth
                                variant="outlined"
                                type="number"
                            />
                            {touched.balance && errors.balance && (
                                <div>{errors.balance}</div>
                            )}
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                {/* Espaçamento adicionado aqui */}
                                <Box mt={2} mb={2}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                            <Button type="submit" variant="contained" color="primary" fullWidth>
                                                Enviar
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                fullWidth
                                                onClick={async (e) => {
                                                    e.preventDefault();
                                                    setLoading(true);
                                                    await handleSubmit(values, null, true);
                                                    resetForm()
                                                }}
                                            >
                                                Salvar e adicionar novo
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                fullWidth
                                                href="/#/banks"
                                            >
                                                Cancelar
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};

export default BankForm;
