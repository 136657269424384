import { Alert, Box, Button, Container, InputAdornment, Link, TextField, Typography } from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';
import { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { loginAction } from '../../redux/authActions';
import { toastSuccess } from '../../components/toast';

const LoginContainer = styled(Container)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: '5rem',
});

const Logo = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '2rem',
});

const LogoImg = styled('img')({
  width: '80px',
  marginRight: '1rem'
});

const Title = styled(Typography)({
  fontWeight: 'bold',
  fontSize: '3rem',
  marginBottom: '2rem',
  color: '#1A237E',
  textShadow: '0 4px 4px rgba(0, 0, 0, 0.25)',
});

const Subtitle = styled(Typography)({
  fontWeight: 'bold',
  fontSize: '1.5rem',
  marginBottom: '2rem',
  color: '#616161',
});

const LoginForm = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  gap: '1.5rem',
  width: '100%',
});

const RegisterBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: '2rem',
});

const RegisterText = styled(Typography)({
  fontSize: '1rem',
  marginBottom: '1rem',
  color: '#616161',
});

const RegisterLink = styled(Link)({
  fontSize: '1rem',
  fontWeight: 'bold',
  color: '#1A237E',
});

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState();
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {

      const loginResponse = await axios.post('/login', {
        email, password
      })
      toastSuccess('Bem vindo!')
      if (loginResponse.status === 200) {
        dispatch(loginAction({ isLoggedIn: true, user: loginResponse.data }));
        navigate('#/cards');
      }
    }
    catch (error) {
      if (error.response) {
        if (error?.response?.data?.error)
          setErrorMessage(error?.response?.data?.error)
      }
    }
  };

  return (
    <LoginContainer maxWidth="sm">
      <div >
        <Logo>
          <LogoImg src="/logo/transparent-png.png" alt="Logo" />
          <Title>Controle Financeiro</Title>
        </Logo>
        <Subtitle>Gerencie suas finanças de forma fácil e segura!</Subtitle>
        {errorMessage && <Alert style={{ marginBottom: '20px' }} severity="error">{errorMessage}</Alert>}
        <LoginForm onSubmit={handleSubmit}>
          <TextField
            label="E-mail"
            variant="outlined"
            type="email"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            label="Senha"
            variant="outlined"
            type="password"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                    color="inherit"
                  >
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <Button variant="contained" type="submit" fullWidth color="primary">
            Entrar
          </Button>
        </LoginForm>
        <RegisterBox>
          <RegisterText>Ainda não tem uma conta?</RegisterText>
          <RegisterLink href="#/singup">Crie sua conta aqui</RegisterLink>
        </RegisterBox>
      </div>
    </LoginContainer>

  );
};

export default connect()(LoginPage);
