import { useEffect } from 'react';

const DisableContextMenu = () => {
    useEffect(() => {
        const handleContextMenu = (event) => {
            event.preventDefault();
        };

        const handleCopy = (event) => {
            event.stopPropagation();
        };

        document.addEventListener('contextmenu', handleContextMenu);
        document.addEventListener('copy', handleCopy);

        return () => {
            document.removeEventListener('contextmenu', handleContextMenu);
            document.removeEventListener('copy', handleCopy);
        };
    }, []);

    return null;
};

export default DisableContextMenu;
