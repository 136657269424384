import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import AccountsAutocompleteComponent from '../../components/autocompleteComponents/AccountsAutocompleteComponent';
import MoneyInput from '../../components/fields/MoneyInput';
import axios from 'axios';
import { useContext, useState } from 'react';
import { LoadingContext } from '../../components/LoadingContext';
import { toastSuccess } from '../../components/toast';
import RouterBreadcrumbs from '../../components/RouterBreadcrumbs';

const TransfersForm = () => {
    const { id } = useParams();
    const [editing, setEditing] = useState(id);

    const navigate = useNavigate();
    const { setLoading } = useContext(LoadingContext)

    const validationSchema = yup.object().shape({
        amount: yup.number().required('Campo obrigatório.').min(0.01, 'Valor deve ser maior que 0.00.'),
        sourceAccountId: yup.number().required('Campo obrigatório.').integer('Número da conta inválido.'),
        destinationAccountId: yup.number().required('Campo obrigatório.').integer('Número da conta inválido.')
            .test('not-same', 'As contas devem ser diferentes.', function (value) {
                const { sourceAccountId } = this.parent;
                return value !== sourceAccountId;
            })
    });


    const handleSubmit = (values) => {
        setLoading(true)
        if (editing) {
            axios.put(`/transfers/${id}`, values)
                .then(() => {
                    setEditing(false);
                    toastSuccess('Transferencia realizada')
                    navigate('/transfers')()
                })
                .catch((error) => console.error(error))
                .finally(() => {
                    setLoading(false)
                })
        } else {
            axios.post('/transfers', values)
                .then(() => {
                    toastSuccess('Transferencia realizada')
                    navigate('/transfers')()
                })
                .catch((error) => console.error(error))
                .finally(() => {
                    setLoading(false)
                })
        }
    };

    return (
        <Formik
            initialValues={{ amount: '', sourceAccountId: '', destinationAccountId: '' }}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {({ errors, touched, values, resetForm }) => (
                <Form>
                    <RouterBreadcrumbs />
                    <Box my={2}>
                        <Typography variant="h6">Nova transferência</Typography>
                    </Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Field
                                as={MoneyInput}
                                id="amount"
                                name="amount"
                                label="Valor"
                                fullWidth
                                variant="outlined"
                            />
                            {touched.amount && errors.amount && (
                                <div>{errors.amount}</div>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Field
                                as={AccountsAutocompleteComponent}
                                id="sourceAccountId"
                                name="sourceAccountId"
                                label="Conta de Origem"
                                fullWidth
                                variant="outlined"
                                hideAccount={values.destinationAccountId}
                            />
                            {touched.sourceAccountId && errors.sourceAccountId && (
                                <div>{errors.sourceAccountId}</div>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Field
                                as={AccountsAutocompleteComponent}
                                id="destinationAccountId"
                                name="destinationAccountId"
                                label="Conta de Destino"
                                fullWidth
                                variant="outlined"
                                hideAccount={values.sourceAccountId}
                            />
                            {touched.destinationAccountId && errors.destinationAccountId && (
                                <div>{errors.destinationAccountId}</div>
                            )}
                        </Grid>
                    </Grid>
                    {/* <Box mt={2}>
                        <Button type="submit" variant="contained" color="primary">
                            Enviar
                        </Button>
                    </Box> */}
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            {/* Espaçamento adicionado aqui */}
                            <Box mt={2} mb={2}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                        <Button type="submit" variant="contained" color="primary" fullWidth>
                                            Enviar
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            fullWidth
                                            onClick={async (e) => {
                                                e.preventDefault();
                                                setLoading(true);
                                                await handleSubmit(values, null, true);
                                                resetForm()
                                            }}
                                        >
                                            Salvar e adicionar novo
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            fullWidth
                                            href='/#/transfers'
                                        >
                                            Cancelar
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};

export default TransfersForm;
