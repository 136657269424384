import React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { mainMenuItensList } from "./menuItemsList";
import { Link } from "react-router-dom";

export const MainListItems = (props) => {


    return (
        <>
            {mainMenuItensList.map((item, index) => (
                <ListItemButton
                    key={index}
                    className="icon-mouse-hover"
                    component={Link}
                    to={item.href}
                >
                    <ListItemIcon>
                        <div>
                            <i className={`${item.icon} icon-beat`}></i>
                        </div>
                    </ListItemIcon>
                    <ListItemText primary={item.primary} />
                </ListItemButton>
            ))}
        </>
    );
};

export const secondaryListItems = [];
