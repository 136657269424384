export const mainMenuItensList = [
    {
        href: "/cards",
        icon: "fa-regular fa-credit-card",
        primary: "Crédito",
        showBottom: true
    },
    {
        href: "/banks",
        icon: "fa-solid fa-wallet",
        primary: "Contas",
        showBottom: true
    },
    {
        href: "/transfers",
        icon: "fa-solid fa-money-bill-transfer",
        primary: "Envio",
        showBottom: false
    },
    {
        href: "/purchases",
        icon: "fa-solid fa-bag-shopping",
        primary: "Despesas",
        showBottom: true
    },
    {
        href: "categories",
        icon: "fa-solid fa-bag-shopping",
        primary: `Categorias`,
        showBottom: true
    },
    {
        href: "/purchases/form",
        icon: "fa-solid fa-bag-shopping",
        primary: `Nova Despesa`,
        showBottom: true
    },
];

