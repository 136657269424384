import React from 'react';
import { Avatar, Box, IconButton, Menu, MenuItem, Typography, } from '@mui/material';
import { Logout } from '@mui/icons-material';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logoutAction } from '../../redux/authActions';
import { startCase, compact } from 'lodash';

function UserAvatarMenu() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const dispatch = useDispatch()
    const history = useNavigate();
    const userLoginInfo = useSelector((state) => state.auth.user);
    const user = userLoginInfo?.user;

    const fullName = formatFullName();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        dispatch(logoutAction())
        history('/login');
    };

    return (
        <>
            <IconButton size="large" onClick={handleClick}>
                <Avatar src={user?.avatar_url || fullName.charAt(0)} alt={fullName} />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <Box sx={{ display: 'flex', alignItems: 'center', p: 2 }}>
                    <Avatar src={user?.avatar_url || fullName.charAt(0)} alt={fullName} sx={{ mr: 2 }} />
                    <Typography variant="subtitle1">{fullName}</Typography>
                </Box>
                <MenuItem onClick={handleLogout}>
                    <Logout fontSize="small" sx={{ mr: 1 }} />
                    <Typography variant="body1">Logout</Typography>
                </MenuItem>
            </Menu>
        </>
    );

    function formatFullName() {
        let fullName;
        if (user?.first_name && user?.last_name) {
            fullName = compact([user.first_name, user.last_name]).join(' ');
        } else if (user?.first_name) {
            fullName = user.first_name;
        } else if (user?.last_name) {
            fullName = user.last_name;
        } else {
            fullName = user?.username;
        }

        fullName = startCase(fullName?.toLowerCase());
        return fullName;
    }
}

export default connect()(UserAvatarMenu);
