import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#BB86FC', // Roxo suave
        },
        secondary: {
            main: '#03DAC6', // Verde água
        },
        background: {
            default: '#121212', // Preto
            paper: '#1E1E1E', // Cinza escuro
        },
        text: {
            primary: '#E0E0E0', // Cinza claro para um bom contraste
            secondary: '#A0A0A0', // Cinza mais suave
        },
        error: {
            main: '#CF6679', // Vermelho suave
        },
        warning: {
            main: '#FFB74D', // Laranja suave
        },
        info: {
            main: '#64B5F6', // Azul suave
        },
        success: {
            main: '#81C784', // Verde suave
        },
        divider: 'rgba(255, 255, 255, 0.12)', // Cinza para separadores
    },
    typography: {
        fontFamily: 'Roboto, sans-serif',
        fontSize: 16,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 700,
        h1: {
            fontSize: '2.5rem',
            fontWeight: 700,
            color: '#FFFFFF',
        },
        h2: {
            fontSize: '2rem',
            fontWeight: 700,
            color: '#FFFFFF',
        },
        h3: {
            fontSize: '1.75rem',
            fontWeight: 700,
            color: '#FFFFFF',
        },
    },
    shape: {
        borderRadius: 12,
    },
    spacing: 8,
    components: {
        MuiFab: {
            styleOverrides: {
                root: {
                    position: 'fixed',
                    bottom: '10%',
                    right: '5%',
                    transition: 'background-color 0.3s',
                    '&:hover': {
                        backgroundColor: '#BB86FC', // Roxo suave
                    },
                    '@media (min-width: 600px)': {
                        bottom: '50px',
                        right: '50px',
                    },
                },
            },
        },
        MuiBottomNavigation: {
            styleOverrides: {
                root: {
                    backgroundColor: '#1E1E1E', // Cinza escuro
                    borderRadius: '12px',
                    overflow: 'hidden',
                },
            },
        },
        MuiBottomNavigationAction: {
            styleOverrides: {
                root: {
                    borderRadius: '12px',
                    backgroundColor: 'transparent',
                    color: '#A0A0A0', // Cinza suave
                    '&.Mui-selected': {
                        backgroundColor: '#BB86FC', // Roxo suave (cor de item selecionado)
                        color: '#FFFFFF', // Branco
                    },
                    '&:hover': {
                        backgroundColor: 'rgba(187, 134, 252, 0.1)', // Efeito hover suave
                    },
                },
            },
        },
    },
});

export default theme;
