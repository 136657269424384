import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';

if ('serviceWorker' in navigator) {
  window?.addEventListener('load', function () {
    navigator.serviceWorker.register('/generate-sw.js', { scope: '/' })
      .then(function (registration) {
        console.log('Service Worker registered with scope:', registration.scope);

        // adiciona o listener ao botão de atualização
        const btnUpdate = document.getElementById('btn-update');
        btnUpdate.addEventListener('click', function () {
          registration.update().then(() => {
            console.log('Service Worker updated!');
          }).catch((error) => {
            console.log('Service Worker update failed:', error);
          });
        });
      })
      .catch(function (error) {
        console.log('Service Worker registration failed:', error);
      });
  });
}

const root = document.getElementById('root');
createRoot(root).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
