const storedAuth = localStorage.getItem('auth');

const initialState = storedAuth
    ? JSON.parse(storedAuth)
    : {
        isLoggedIn: localStorage.getItem('isLoggedIn'),
        user: JSON.parse(localStorage.getItem('user')),
    };

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'LOGIN':
            localStorage.setItem('user', JSON.stringify(action.payload.user));
            localStorage.setItem('isLoggedIn', true);
            localStorage.setItem('token', action?.payload?.user?.user?.token);
            return {
                isLoggedIn: true,
                user: action.payload.user,
            };
        case 'LOGOUT':
            localStorage.clear()
            return {
                isLoggedIn: false,
                user: null,
            };
        case 'GET_USER':
            return {
                isLoggedIn: true,
                user: JSON.parse(localStorage.getItem('user')),
            };
        default:
            return state;
    }
};

export default authReducer;