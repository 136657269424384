import { formatToReais } from '../../utils/money';
import { AutocompleteComponent } from '../fields/AutocompleteComponent';

export const AccountsAutocompleteComponent = ({ hideAccount, ...rest }) => {
    const handleOnChange = (event, value) => {
        rest.onChange(`${value?.id}`);
    };

    return (
        <AutocompleteComponent
            {...rest}
            path="/accounts/"
            getOptionLabel={(option) => {
                return `${option.name} ${rest.showBalance ? " - " + formatToReais(option.balance) : ''}`;
            }}
            onChange={handleOnChange}
        />
    );
};

export default AccountsAutocompleteComponent;
