import React, { useState } from "react";
import LoadingBackdrop from "./LoadingBackdrop";

const LoadingContext = React.createContext({
    loading: false,
    setLoading: () => { },
});

function LoadingProvider({ children }) {
    const [loading, setLoading] = useState(false);

    return (
        <LoadingContext.Provider value={{ loading, setLoading }}>
            {children}
            <LoadingBackdrop loading={loading} />
        </LoadingContext.Provider>
    );
}

export { LoadingContext, LoadingProvider };
