import { useCallback } from 'react';
import { AutocompleteComponent } from '../fields/AutocompleteComponent'
import { Box } from '@mui/material';


export const CategoriesAutocompleteComponent = ({ formikProps, ...rest }) => {
    const onChange = useCallback(
        (event, value) => {
            if (value === null) {
                return;
            }

            rest.onChange(`${value.id}`);
        },
        [rest]
    );

    return (
        <AutocompleteComponent
            {...rest}
            path='/categories'
            getOptionLabel={option => option.name}
            onChange={onChange}
            renderOption={(props, option) => (
                <Box
                    component="li"
                    sx={{ display: 'flex', alignItems: 'center', '& > span': { mr: 2, flexShrink: 0 } }}
                    {...props}
                >
                    <span className="material-symbols-outlined">
                        {option.icon}
                    </span>
                    {option.name}
                </Box>
            )}
        />
    );
};


export default CategoriesAutocompleteComponent;
