import React, { useEffect, useState } from 'react';
import ResponsiveTable from '../../components/ResponsiveTable';
import axios from 'axios';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { Fab } from '@mui/material';
// import DeleteButton from '../../components/DeleteButton';
// import { toastSuccess } from '../../components/toast';
import RouterBreadcrumbs from '../../components/RouterBreadcrumbs';


const CategoriesPage = () => {
    const [dataList, setDataList] = useState([]);
    const [loading, setLoading] = useState(false);

    const headers = [
        { attribute: 'name', label: 'Nome' },
        { attribute: 'description', label: 'Descrição' },
        { attribute: 'icon', label: 'Icone', type: 'material-icon' },
        // {
        //     label: 'Ações',
        //     width: '15%',
        //     attribute: '',
        //     customRender: (category) => {
        //         if (!category) {
        //             return null;
        //         }

        //         return (
        //             <DeleteButton
        //                 onConfirm={async () => {
        //                     try {
        //                         if (category.id) {
        //                             await axios.delete(`/categories/${category.id}`);
        //                             await getDataToList();
        //                             toastSuccess('Categoria excluída');
        //                         } else {
        //                             console.error('Erro ao excluir categoria: Categoria sem id');
        //                         }
        //                     } catch (ex) {
        //                         console.error('Erro ao excluir categoria', ex);
        //                     }
        //                 }}
        //                 title="Excluir Categoria"
        //                 message={`Você tem certeza que deseja excluir a categoria "${category?.name || 'sem nome'}"?`}
        //             />
        //         );
        //     },
        // },
    ];

    async function getDataToList() {
        try {
            setLoading(true);
            const response = await axios.get('/categories');
            if (response.data) {
                setDataList(response.data);
            } else {
                console.error('Erro ao carregar categorias: Resposta sem dados');
            }
            setLoading(false);
        } catch (ex) {
            console.error('Erro ao carregar categorias', ex);
            setLoading(false);
        }
    }

    useEffect(() => {
        getDataToList();
    }, []);

    return (
        <>
            <RouterBreadcrumbs />
            <h1>Categorias</h1>
            <ResponsiveTable headers={headers} data={dataList} loading={loading} />
            <Link to="/categories/form" style={{ position: 'fixed', bottom: '24px', right: '24px' }}>
                <Fab color="primary" aria-label="add">
                    <AddIcon />
                </Fab>
            </Link>
        </>
    );
};

export default CategoriesPage;
