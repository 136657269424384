import React, { useEffect, useState } from 'react';
import ResponsiveTable from '../../components/ResponsiveTable';
import axios from 'axios';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { Fab } from '@mui/material';
import DeleteButton from '../../components/DeleteButton';
import { toastSuccess } from '../../components/toast';
import RouterBreadcrumbs from '../../components/RouterBreadcrumbs';

const TransfersPage = () => {
    const [dataList, setDataList] = useState([]);
    const [loading, setLoading] = useState([]);

    const headers = [
        { attribute: 'transferDate', label: 'Data de Transferência', type: 'date' },
        { attribute: 'amount', label: 'Valor' },
        { attribute: 'sourceAccount', label: 'Conta de Origem', customRender: (value) => value?.name },
        { attribute: 'destinationAccount', label: 'Conta de Destino', customRender: (value) => value?.name },
        {
            label: 'Ações',
            width: '15%',
            attribute: '',
            customRender: (transfer) => {
                return (
                    <>
                        <RouterBreadcrumbs />
                        <DeleteButton
                            onConfirm={async () => {
                                try {
                                    await axios.delete(`/transfers/${transfer.id}`);
                                    await getDataToList();
                                    toastSuccess('Transferencia excluida')
                                } catch (ex) { }
                            }}
                            title="Excluir cartão"
                            message={`Você tem certeza que deseja excluir essa transferencia?`}
                        />
                    </>
                );
            },
        },
    ];

    async function getDataToList() {
        try {
            setLoading(true)
            const response = await axios.get('/transfers');
            setDataList(response.data);
            setLoading(false)
        } catch (ex) {
            setLoading(false)
        }
    }

    useEffect(() => {
        getDataToList();
    }, []);

    return (
        <>
            <RouterBreadcrumbs />
            <h1>Transferencias Entre Contas</h1>
            <ResponsiveTable headers={headers} data={dataList} loading={loading} />
            <Link to="/transfers/form" style={{ position: 'fixed', bottom: '24px', right: '24px' }}>
                <Fab color="primary" aria-label="add">
                    <AddIcon />
                </Fab>
            </Link>
        </>
    );
};

export default TransfersPage; 
