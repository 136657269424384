/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import ResponsiveTable from '../../../components/ResponsiveTable';
import PaymentIcon from '@mui/icons-material/Payment';
import CustomizableButton from '../../../components/fields/CustomizableButton';
import _ from 'lodash';
import { toastSuccess } from '../../../components/toast';
import RouterBreadcrumbs from '../../../components/RouterBreadcrumbs';
import * as yup from "yup";
import { Formik, Form, Field } from "formik";
import { Box, Grid, Typography } from '@mui/material';
import MoneyInput from '../../../components/fields/MoneyInput';
import AccountsAutocompleteComponent from '../../../components/autocompleteComponents/AccountsAutocompleteComponent';

const CreditCardInvoicesPage = () => {


    const [dataList, setDataList] = useState([]);
    const [card, setCard] = useState(null);
    const { creditCardId } = useParams();
    const [accountId, setAccountId] = useState(null)
    const [valueToPay, setValueToPay] = useState(null)
    async function getDataToList() {
        const [invoices, actualCard] = await Promise.all([await axios.get(`/cards/${creditCardId}/invoices`), await axios.get(`/cards/${creditCardId}`)]);
        setDataList(_.orderBy(invoices.data, 'invoiceDate', 'asc'));
        setCard(actualCard.data);
    }

    useEffect(() => {
        getDataToList();
    }, [creditCardId]);


    const validationSchema = yup.object().shape({
        accountId: yup
            .string()
            .trim()
            .required("O nome é obrigatório")
            .max(100, "O nome deve ter no máximo 100 caracteres"),
        valueToPay: yup
            .number()
            .required("O limite de crédito é obrigatório")
            .positive("O limite de crédito deve ser positivo")
            .typeError("O limite de crédito deve ser um número válido"),
    });


    const headers = [
        // { attribute: 'id', label: 'ID' },
        // { attribute: 'creditCardId', label: 'ID do Cartão de Crédito' },
        { attribute: 'invoiceDate', type: 'date', label: 'Data da Fatura' },
        { attribute: 'dueDate', type: 'date', label: 'Data de Vencimento' },
        { attribute: 'totalAmount', type: 'money', label: 'Valor Total' },
        { attribute: 'paid', label: 'Pago', type: 'boolean' },
        {
            label: 'Ações',
            attribute: '',
            customRender: (invoice) => {
                return (
                    <>
                        {true &&
                            <CustomizableButton
                                icon={<PaymentIcon />}
                                title=""
                                onConfirm={async () => {
                                    try {
                                        await axios.put(`/cards/${creditCardId}/invoices/${invoice.id}/pay`, {
                                            accountId,
                                            valueToPay
                                        });
                                        await getDataToList();
                                        toastSuccess('Fatura paga')
                                    } catch (ex) { }
                                }}
                                message={""}
                                customFields={() => {
                                    return <Formik
                                        enableReinitialize={true}
                                        validationSchema={validationSchema}
                                        initialValues={{
                                            accountId: '',
                                            valueToPay: invoice.totalAmount,
                                        }}

                                    >
                                        {({ errors, touched, values, resetForm }) => (
                                            <Form>
                                                <Box my={2}>
                                                    <Typography variant="h6">Conta para desconto</Typography>
                                                </Box>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <Field
                                                            as={AccountsAutocompleteComponent}
                                                            id="accountId"
                                                            name="accountId"
                                                            label="Conta de Origem"
                                                            fullWidth
                                                            variant="outlined"
                                                            showBalance={true}
                                                            onChange={(value) => {
                                                                setAccountId(value)
                                                            }}
                                                        />
                                                        {touched.accountId && errors.accountId && (
                                                            <div>{errors.accountId}</div>
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <Field
                                                            as={MoneyInput}
                                                            id="valueToPay"
                                                            name="valueToPay"
                                                            label="Valor a ser Descontado"
                                                            fullWidth
                                                            variant="outlined"
                                                            onChange={(value) => {
                                                                setValueToPay(value)
                                                            }}
                                                        />
                                                        {touched.limitCredit && errors.limitCredit && (
                                                            <div>{errors.limitCredit}</div>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </Form>
                                        )}
                                    </Formik>
                                }}
                            />
                        }
                    </>
                );
            },
        },
    ];

    return (
        <>
            <RouterBreadcrumbs />
            <h1>Faturas do Cartão de Crédito "{card?.name}"</h1>
            <ResponsiveTable headers={headers} data={dataList} />
        </>
    );
};

export default CreditCardInvoicesPage;
