import React from 'react';
import PropTypes from 'prop-types';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import { formatToReais } from '../utils/money';

const ResponsiveTable = React.memo(({ headers, data, loading }) => {
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));

  const renderCellContent = React.useCallback(
    (header, value) => {
      if (header.customRender) return header.customRender(value);

      switch (header.type) {
        case 'money':
          return formatToReais(value || "0");
        case 'date':
          return new Date(value).toLocaleDateString('pt-BR');
        case 'day':
          return `Dia ${value}`;
        case 'percentage':
          return `${value}%`;
        case 'boolean':
          return value ? 'Sim' : 'Não';
        case 'link':
          return <a href={value} target="_blank" rel="noopener noreferrer">{value}</a>;
        case 'image':
          return <img src={value} alt="Imagem" style={{ maxWidth: '100%' }} />;
        case 'color':
          return (
            <div
              style={{
                backgroundColor: value,
                width: '100%',
                height: '100%',
                borderRadius: '4px',
              }}
            />
          );
        case 'material-icon':
          return <span className="material-symbols-outlined">{value}</span>;
        case 'category':
          return value?.icon ? (
            <>
              <span>{value.name}</span>
              <span className="material-symbols-outlined">{value.icon}</span>
            </>
          ) : value;
        case 'custom':
          return header.customRender(value);
        default:
          return value;
      }
    },
    []
  );

  if (isSmDown) {
    return (
      <Box sx={{ marginBottom: '5%' }}>
        {loading ? (
          [...Array(5)].map((_, index) => (
            <Skeleton
              key={index}
              variant="rectangular"
              width="100%"
              height={118}
              sx={{ marginBottom: '5%', borderRadius: '8px' }}
            />
          ))
        ) : data.length === 0 ? (
          <Typography variant="body2" sx={{ padding: 2, textAlign: 'center' }}>
            Nenhum dado para exibir
          </Typography>
        ) : (
          data.map((row, index) => (
            <Card key={index} sx={{ marginBottom: '5%', borderRadius: '8px' }}>
              <CardContent>
                {headers.map(header => (
                  <Typography
                    key={header.attribute || header.label}
                    variant="body2"
                    sx={{ margin: 0.5 }}
                  >
                    <strong>{header.label}:</strong>{' '}
                    {renderCellContent(
                      header,
                      header.attribute ? row[header.attribute] : row
                    )}
                  </Typography>
                ))}
              </CardContent>
            </Card>
          ))
        )}
      </Box>
    );
  } else {
    return (
      <TableContainer component={Paper} sx={{ marginBottom: '5%', borderRadius: '8px' }}>
        <Table>
          <TableHead>
            <TableRow>
              {headers.map(header => (
                <TableCell key={header.attribute || header.label} width={header.width}>
                  {header.customType ? header.customType() : header.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              [...Array(headers.length)].map((_, index) => (
                <TableRow key={index}>
                  {headers.map(header => (
                    <TableCell key={header.attribute || header.label}>
                      <Skeleton variant="text" />
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : data.length === 0 ? (
              <TableRow>
                <TableCell colSpan={headers.length} align="center">
                  Nenhum dado para exibir
                </TableCell>
              </TableRow>
            ) : (
              data.map((row, index) => (
                <TableRow key={index} hover>
                  {headers.map(header => (
                    <TableCell key={header.attribute || header.label}>
                      {renderCellContent(
                        header,
                        header.attribute ? row[header.attribute] : row
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
});

ResponsiveTable.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      attribute: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      type: PropTypes.string,
      customType: PropTypes.func,
      customRender: PropTypes.func,
      width: PropTypes.string,
    })
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ResponsiveTable;
