import { toast } from 'react-toastify';



export const toastNotification = (message) => {
    if (message && message.length)
        toast(message, {
            closeButton: true,
        });
}

export const toastSuccess = (message) => {
    if (message && message.length)
        toast.success(message, {
            closeButton: true,
        });
}

export const toastError = (message) => {
    console.log('test', message)
    if (message && message.length)
        toast.error(message, {
            closeButton: true,
        });
}