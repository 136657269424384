import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

function MoneyInput(props) {
    const { id, name, label, fullWidth, variant } = props;
    const { min = 0, max = Infinity } = props;
    const { value, onChange } = props;
    const [error, setError] = useState(false);

    const formatValue = (value) => {
        return value?.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
        });
    };

    const handleChange = (event) => {
        const value = Number(event.target.value);
        if (value >= min && value <= max) {
            setError(false);
        } else {
            setError(true);
        }
        onChange(event);
    };

    return (
        <TextField
            id={id}
            name={name}
            label={label}
            fullWidth={fullWidth}
            variant={variant}
            type="number"
            value={value}
            onChange={handleChange}
            error={error}
            helperText={error && `O valor deve estar entre ${formatValue(min)} e ${formatValue(max)}`}
            InputProps={{
                startAdornment: <InputAdornment position="start">R$</InputAdornment>,
            }}
        />
    );
}

export default MoneyInput;
