import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

function LoadingBackdrop({ loading }) {
    return (
        <Backdrop
            open={loading}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    );
}

export default LoadingBackdrop;
