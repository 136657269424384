import React from 'react';
import { Box, Button, Container, TextField, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';

const SignupContainer = styled(Container)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '5rem',
});

const Logo = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    marginBottom: '2rem',
});

const LogoImg = styled('img')({
    width: '60px',
    marginRight: '1rem',
});

const Title = styled(Typography)({
    fontWeight: 'bold',
    fontSize: '2.5rem',
    marginBottom: '2rem',
    color: '#fff',
    // textShadow: '0 4px 4px rgba(0, 0, 0, 0.25)',
});

const SignupForm = styled(Form)({
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
    width: '100%',
});

const SignupSchema = Yup.object().shape({
    username: Yup.string().required('Digite seu nome'),
    email: Yup.string()
        .email('E-mail inválido')
        .required('Digite seu e-mail'),
    password: Yup.string()
        .min(8, 'Senha muito curta')
        .required('Digite sua senha'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password')], 'Senhas não conferem')
        .required('Confirme sua senha'),
});

const SignupPage = () => {
    const initialValues = {
        username: '',
        email: '',
        password: '',
    };

    const Navigate = useNavigate()

    const handleSubmit = async (values) => {
        try {
            await axios.post('/users', values)
            Navigate('/login')
        } catch (ex) {

        }
    };

    return (
        <SignupContainer maxWidth="sm">
            <Logo>
                <LogoImg src="/logo/transparent-png.png" alt="Logo" />
                <Title>Crie sua Conta</Title>
            </Logo>
            <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={SignupSchema}>
                {({ isSubmitting, touched, errors }) => {
                    return (
                        <SignupForm>
                            <Field
                                name="username"
                                as={TextField}
                                label="Nome"
                                variant="outlined"
                                fullWidth
                                helperText={<ErrorMessage name="username" />}
                                error={touched.username && Boolean(errors.username)}
                            />
                            <Field
                                name="email"
                                as={TextField}
                                label="E-mail"
                                variant="outlined"
                                type="email"
                                fullWidth
                                helperText={<ErrorMessage name="email" />}
                                error={touched.email && Boolean(errors.email)}
                            />
                            <Field
                                name="password"
                                as={TextField}
                                label="Senha"
                                variant="outlined"
                                type="password"
                                fullWidth
                                helperText={<ErrorMessage name="password" />}
                                error={touched.password && Boolean(errors.password)}
                            />
                            <Field
                                name="confirmPassword"
                                as={TextField}
                                label="Confirmação da Senha"
                                variant="outlined"
                                type="password"
                                fullWidth
                                helperText={<ErrorMessage name="confirmPassword" />}
                                error={touched.confirmPassword && Boolean(errors.confirmPassword)}
                            />

                            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                                <Button variant="contained" type="submit" disabled={isSubmitting}>
                                    Criar Conta
                                </Button>
                                <Link to="/login">
                                    <Button variant="outlined" sx={{ ml: 1 }}>
                                        Cancelar
                                    </Button>
                                </Link>
                            </Box>
                        </SignupForm>
                    )
                }}
            </Formik>
        </SignupContainer>
    );
};

export default SignupPage;
